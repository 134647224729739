import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IEmployee } from 'api/models/Employee';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { ButtonSecondary } from 'components/common/inputs/Button';
import YearSelect from 'components/common/atoms/YearSelect';
import EmployeeAbsenceAccordion from './EmployeeAbsenceAccordion';
import { EmployeeAbsenceDialog } from 'components/common/elements/employee/EmployeeAbsenceDialog';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { useEmployeesAPI } from 'api/controllers/EmployeesAPI';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-block-start: -0.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const SectionHeadline = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const AddAbsenceButton = styled(ButtonSecondary)`
  //
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`;

export const EmployeeDetailsAbsences: FC<{ employee: IEmployee; onAbsencesChange: (absences: IEmployeeAbsence[]) => void }> = (props) => {
  const { employee, onAbsencesChange } = props;
  const { id } = employee;

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [openAccordionItems, setOpenAccordionItems] = useState<('vacation' | 'disease' | 'inactive')[]>([
    'vacation',
    'disease',
    'inactive',
  ]);

  const employeesAPI = useEmployeesAPI();
  const [absences, setAbsences] = useState<IEmployeeAbsence[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IEmployeeAbsence | undefined>(undefined);

  const handleOpenDialog = (item?: IEmployeeAbsence) => {
    setCurrentItem(item);
    setOpenDialog(true);
  };

  const fetchAbsences = useCallback(() => {
    const startDate = new Date(year, 0, 1, 0, 0, 0, 0).toISOString();
    const endDate = new Date(year, 11, 31, 0, 0, 0, 0).toISOString();
    setLoading(true);
    employeesAPI
      .getEmployeeAbsences({ id, startDate, endDate })
      .then((res) => {
        setAbsences(res.data);
        onAbsencesChange(res.data);
      })
      .catch((err) => {
        console.log('fetchAbsences error:', err);
      })
      .finally(() => setLoading(false));
  }, [id, employeesAPI, year, onAbsencesChange]);

  useEffect(() => {
    fetchAbsences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const toggleAccordion = (value: 'vacation' | 'disease' | 'inactive') => {
    if (openAccordionItems.includes(value)) {
      setOpenAccordionItems((state) => state.filter((item) => item !== value));
    } else {
      setOpenAccordionItems((state) => [...state, value]);
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <SectionHeadline>Abwesenheiten:</SectionHeadline>
        <YearSelect year={year} setYear={setYear} />
        <AddAbsenceButton onClick={() => handleOpenDialog()}>Abwesenheit eintragen</AddAbsenceButton>
      </HeaderWrapper>

      {loading && (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      )}

      {!loading && (
        <>
          <EmployeeAbsenceAccordion
            id={id}
            open={openAccordionItems.includes('vacation')}
            toggle={() => toggleAccordion('vacation')}
            headline={'Bezahlter Urlaub'}
            items={absences.filter((item) => item.type === 'vacation')}
            handleOpenDialog={handleOpenDialog}
          />

          <EmployeeAbsenceAccordion
            id={id}
            open={openAccordionItems.includes('disease')}
            toggle={() => toggleAccordion('disease')}
            headline={'Krankheit'}
            items={absences.filter((item) => item.type === 'disease')}
            handleOpenDialog={handleOpenDialog}
          />

          <EmployeeAbsenceAccordion
            id={id}
            open={openAccordionItems.includes('inactive')}
            toggle={() => toggleAccordion('inactive')}
            headline={'Inaktivität'}
            items={absences.filter((item) => item.type === 'inactive')}
            handleOpenDialog={handleOpenDialog}
          />
        </>
      )}

      {openDialog && <EmployeeAbsenceDialog id={id} item={currentItem} onSuccess={fetchAbsences} onClosed={() => setOpenDialog(false)} />}
    </Wrapper>
  );
};
