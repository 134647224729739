// General
import { FC } from 'react';

// Components
import { CardColumn, CardRow, CardRowHeadline, CardsWrapper, Card } from 'components/common/atoms/Card';

// Types
import { ICustomer } from 'api/models/Customer';

export const CustomerDetailsInformation: FC<{ customer: ICustomer }> = (props) => {
  const { customer } = props;

  return (
    <CardsWrapper>
      <Card>
        <CardRow>
          <CardRowHeadline>Einrichtung</CardRowHeadline>
          <CardColumn>{customer?.displayName || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Straße</CardRowHeadline>
          <CardColumn>{customer?.address?.addressLine1 || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Adresszusatz</CardRowHeadline>
          <CardColumn>{customer?.address?.addressLine2 || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Postleitzahl</CardRowHeadline>
          <CardColumn>{customer?.address?.zipCode || '-'}</CardColumn>
        </CardRow>
        <CardRow>
          <CardRowHeadline>Stadt</CardRowHeadline>
          <CardColumn>{customer?.address?.city || '-'}</CardColumn>
        </CardRow>
      </Card>

      <Card>
        <CardRow>
          <CardRowHeadline>Anmerkungen</CardRowHeadline>
          <CardColumn>{customer?.comment || '-'}</CardColumn>
        </CardRow>
      </Card>
    </CardsWrapper>
  );
};
