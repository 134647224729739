import { IEmployee } from './Employee';
import { IAPIModelBaseV2 } from './index';
import type { EmployeeAbsenceItem } from 'json-schemas/EmployeeAbsence.Item';

export interface IEmployeeAbsence extends EmployeeAbsenceItem { }

export type TAbsenceType = 'disease' | 'vacation' | 'inactive';

export const getAbsenceTypeString = (type: TAbsenceType | string) => {
  switch (type) {
    case 'disease':
      return 'Krankheit';
    case 'vacation':
      return 'Urlaub';
    case 'inactive':
      return 'Inaktiv';
    default:
      return 'Abwesend';
  }
};

export const getAbsenceTypeStringInvoice = (type: TAbsenceType | string) => {
  switch (type) {
    case 'disease':
      return 'Krankentag';
    case 'vacation':
      return 'Urlaubstag';
    case 'public_holiday':
      return 'Gesetzlicher Feiertag';
    case 'inactive':
    default:
      return 'Abwesenheit';
  }
};

export function getAbsenceTypeLabel(absence: IEmployeeAbsence) {
  switch (absence?.type || '') {
    case 'vacation':
      return 'Urlaub';
    case 'disease':
      return 'Krankheit';
    case 'inactive':
      return 'Inaktiv';
    default:
      return 'Abwesend';
  }
}
