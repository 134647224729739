import { FC, useState } from 'react';
import styled from 'styled-components';
import { Body2Bold, Body3, Headline2, Headline3 } from 'styles/FontStyles';
import { ButtonPrimary } from 'components/common/inputs/Button';
import { BREAKPOINT_LG, BREAKPOINT_MD } from 'styles/Breakpoints';
import { TextInput } from 'components/common/inputs/TextInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { Clickable } from 'components/common/atoms/Clickable';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form } from 'components/common/inputs/Form';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { ICustomer } from 'api/models/Customer';
import { Dropdown } from 'components/common/inputs/Dropdown';
import { Trash } from 'components/icons/Trash';
import { Plus } from 'components/icons/Plus';
import { DROPDOWN_ITEMS_REGIONS_CUSTOMER } from 'api/models/Region';
import { ICustomerCategory } from 'api/models/CustomerCategory';
import { ICustomerOrganization } from 'api/models/CustomerOrganization';
import { Link } from 'components/common/atoms/Link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 2.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  ${Headline3};
`;

const ContactTitle = styled.h4`
  ${Body2Bold};
  grid-column: 1 / -1;

  ${BREAKPOINT_LG} {
    grid-column: 1;
    grid-area: 1 / 1 / 5 / 1;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const GridRow = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`;

const GridRowInline = styled(Row)`
  ${BREAKPOINT_MD} {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;

    ${BREAKPOINT_LG} {
      grid-template-columns: auto 1fr 1fr;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 16rem;
`;

const AddButton = styled(Clickable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  ${Body3};

  align-self: flex-start;
  padding-bottom: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
`;

const RemoveButton = styled(AddButton)`
  align-self: flex-end;
  width: min-content;
`;

export const CustomerForm: FC<{
  customer?: ICustomer;
  customerCategories: ICustomerCategory[];
  customerOrganizations: ICustomerOrganization[];
}> = (props) => {
  const { customer = null, customerCategories, customerOrganizations } = props;

  const navigate = useNavigate();

  const customersAPI = useCustomersAPI();
  const [isSubmitting, setSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      displayName: customer?.displayName || '',
      internalId: customer?.internalId || '',
      type: customer?.type || '',
      debtorNumber: customer?.debtorNumber || '',
      address: {
        id: customer?.address?.id || undefined,
        addressLine1: customer?.address?.addressLine1 || '',
        addressLine2: customer?.address?.addressLine2 || '',
        zipCode: customer?.address?.zipCode || '',
        city: customer?.address?.city || '',
        country: customer?.address?.country || 'de',
      },
      region: customer?.region || '',
      comment: customer?.comment || '',
      contactPersons: (customer?.contactPersons || [])
        .map(({ formattedPhoneNumber, ...item }) => ({
          ...item,
          phoneNumber: formattedPhoneNumber,
        }))
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt)),
      customerCategoryId: customer?.customerCategoryId || null,
      customerOrganizationId: customer?.customerOrganizationId || null,
    },
  });

  const contacts = useFieldArray({ name: 'contactPersons' as never, control: form.control, keyName: 'form-id' });

  const customerCategoryOptions = customerCategories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const customerOrganizationsOptions = customerOrganizations.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  return (
    <Wrapper>
      <Form
        form={form}
        onSubmit={(values) => {
          setSubmitting(true);
          (customer?.id ? customersAPI.updateCustomer(customer?.id, values) : customersAPI.createCustomer(values))
            .then((res) => {
              navigate(`/customers/${res.data.data.id}`, { replace: true });
            })
            .catch((err) => {
              // TODO add generic error message
            })
            .finally(() => setSubmitting(false));
        }}
      >
        <Title>Einrichtungsprofil</Title>

        <Section>
          <SectionTitle>Allgemeine Informationen</SectionTitle>
          <GridRow>
            <TextInput
              id={'displayName'}
              placeholder={'z.B. Graf-Recke-Stiftung'}
              label={'Name der Einrichtung'}
              $plain
              {...form.register('displayName', { required: 'Bitte geben Sie einen Namen an.' })}
            />
            <TextInput
              id={'internalId'}
              placeholder={'z.B. G-RS'}
              label={'Kürzel der Einrichtung'}
              $plain
              {...form.register('internalId', { required: 'Bitte geben Sie einen Kürzel an.' })}
            />
          </GridRow>
          <GridRow>
            <TextInput
              id={'debtorNumber'}
              placeholder={'z.B. 12345'}
              label={'Debitorennummer'}
              $plain
              {...form.register('debtorNumber', { required: 'Bitte geben Sie eine Debitorennummer an.' })}
            />
          </GridRow>
          <GridRow>
            <TextInput
              id={'address.addressLine1'}
              placeholder={'Straße und Hausnummer eingeben'}
              label={'Straße und Hausnummer'}
              $plain
              {...form.register('address.addressLine1', { required: 'Bitte geben Sie einen Straße und Hausnummer an.' })}
            />
            <TextInput optional id={'address.addressLine2'} placeholder={'Adresszusatz eingeben'} label={'Adresszusatz'} $plain />
          </GridRow>
          <GridRow>
            <TextInput
              id={'address.zipCode'}
              placeholder={'Postleitzahl eingeben'}
              label={'PLZ'}
              $plain
              {...form.register('address.zipCode', { required: 'Bitte geben Sie eine PLZ an.' })}
            />
            <TextInput
              id={'address.city'}
              placeholder={'Stadt eingeben'}
              label={'Stadt'}
              $plain
              {...form.register('address.city', { required: 'Bitte geben Sie eine Stadt an.' })}
            />
          </GridRow>
          <GridRow>
            <div>
              <StyledDropdown
                id={'customerCategoryId'}
                items={customerCategoryOptions}
                placeholder={'Art auswählen'}
                label={'Art der Einrichtung'}
                $plain
                {...form.register('customerCategoryId', { required: 'Bitte wählen Sie eine Kategorie aus.' })}
              />
              <Link href={'/customer-categories/add'} target="_blank">
                Neue Kundenart erstellen
              </Link>
            </div>

            <div>
              <StyledDropdown
                optional
                id={'customerOrganizationId'}
                items={customerOrganizationsOptions}
                placeholder={'Organisation auswählen'}
                label={'Organisation'}
                $plain
              />
              <Link href={'/customer-organizations/add'} target="_blank">
                Neue Organisation erstellen
              </Link>
            </div>

            <StyledDropdown
              optional
              id={'region'}
              items={DROPDOWN_ITEMS_REGIONS_CUSTOMER}
              placeholder={'Region auswählen'}
              label={'Region'}
              $plain
              {...form.register('region', { required: 'Bitte wählen Sie eine Region aus.' })}
            />
          </GridRow>
        </Section>

        <Section>
          <SectionTitle>Kontakt</SectionTitle>
          {contacts.fields.map((field, index) => (
            <GridRowInline key={field['form-id']}>
              <ContactTitle>{index + 1}. Kontakt</ContactTitle>
              <TextInput
                optional
                id={`contactPersons.${index}.emailAddress`}
                placeholder={'beispiel@gmail.com'}
                label={'E-Mail-Adresse'}
                $plain
              />
              <TextInput
                optional
                id={`contactPersons.${index}.phoneNumber`}
                placeholder={'z.B. 01578-2983456'}
                label={'Telefonnummer'}
                $plain
              />
              <TextInput optional optionalLabel id={`contactPersons.${index}.firstName`} placeholder={'Vorname'} label={'Vorname'} $plain />
              <TextInput
                optional
                optionalLabel
                id={`contactPersons.${index}.lastName`}
                placeholder={'Nachname'}
                label={'Nachname'}
                $plain
              />
              <TextInput
                optional
                optionalLabel
                id={`contactPersons.${index}.role`}
                placeholder={'Rolle der Person eingeben'}
                label={'Rolle in der Einrichtung'}
                $plain
              />
              <RemoveButton
                onClick={() => {
                  contacts.remove(index);
                }}
              >
                <Trash />
              </RemoveButton>
            </GridRowInline>
          ))}
          <GridRow>
            <AddButton
              onClick={() => {
                contacts.append({ firstName: '', lastName: '', emailAddress: '', phoneNumber: '' });
              }}
            >
              <Plus /> Ansprechpartner hinzufügen
            </AddButton>
          </GridRow>
        </Section>

        <Section>
          <Row>
            <TextInput
              optional
              id={'comment'}
              type={'textarea'}
              placeholder={'Anmerkungen hinzufügen...'}
              label={'Anmerkungen'}
              style={{ flex: 1 }}
            />
          </Row>
        </Section>

        <ButtonPrimary type={'submit'}>{isSubmitting ? <LoadingSpinner /> : 'Speichern'}</ButtonPrimary>
      </Form>
    </Wrapper>
  );
};
